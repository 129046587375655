import { HTTP } from '../../axios/axios'

async function getAll (lang) {
  try {
    const res = []
    const resEn = await HTTP.get('/blogs-category', { headers: { 'accept-language': 'en' } })
    const resRu = await HTTP.get('/blogs-category', { headers: { 'accept-language': 'ru' } })
    const resAz = await HTTP.get('/blogs-category', { headers: { 'accept-language': 'az' } })
    for (const i in resEn.data) {
      res.push({ en: resEn.data[i].name, ru: resRu.data[i].name, az: resAz.data[i].name, _id: resEn.data[i]._id })
    }
    return res
  } catch (error) {
    console.log(error)
  }
}
async function addOne (blogsCategory) {
  const categories = [
    { lang: 'en', name: blogsCategory.en },
    { lang: 'az', name: blogsCategory.az },
    { lang: 'ru', name: blogsCategory.ru }
  ]
  return await HTTP.post('/blogs-category', { categories })
}
async function update (blogsCategory, id) {
  await HTTP.patch('/blogs-category/' + id, { name: blogsCategory.en }, { headers: { 'accept-language': 'en' } })
  await HTTP.patch('/blogs-category/' + id, { name: blogsCategory.az }, { headers: { 'accept-language': 'az' } })
  await HTTP.patch('/blogs-category/' + id, { name: blogsCategory.ru }, { headers: { 'accept-language': 'ru' } })
}
async function deleteOne (blogsCategory) {
  return await HTTP.delete('/blogs-category/' + blogsCategory._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/blogs-category/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
